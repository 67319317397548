import React, { useEffect } from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { StaticImage } from "gatsby-plugin-image"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/index.css"
import { Typewriter } from "react-simple-typewriter"

const IndexPage = () => {
  const {
    site: { info },
    backgroundImage1,
    backgroundImage,
  } = useStaticQuery(
    graphql`
      query {
        site {
          info: siteMetadata {
            description
            siteUrl
            author
          }
        }
        backgroundImage: file(relativePath: { eq: "LANDSCAPE_HOME_A.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        backgroundImage1: file(relativePath: { eq: "LANDSCAPE_HOME_B.png" }) {
          childImageSharp {
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(backgroundImage)
  const pluginImage1 = getImage(backgroundImage1)
  const intl = useIntl()
  let start = 0
  const scroll = () => {
    let arrow = document.querySelector(".home-arrow")
    let arrow1 = document.querySelector(".arrow")
    let st = window.scrollY || document.documentElement.scrollTop
    if (st > 200) {
      if (st > start) {
        arrow.style.width = "0"
        arrow1.style.backgroundColor = "transparent"
      } else {
        arrow.style.width = "100vw"
        arrow1.style.backgroundColor = "#ff00ff"
      }
    }
    start = st
  }
  useEffect(() => {
    document.addEventListener("scroll", scroll)
    return () => {
      document.removeEventListener("scroll", scroll)
    }
  })

  const schema = {
    "@context": "https://schema.org/",
    "@type": "Person",
    name: "Perina Kulić",
    description: info.description,
    url: info.siteUrl,
    image: "/static/93ea800524e708f6764b9ed08f710975/MINI.svg",
    sameAs: [
      "https://media-exp1.licdn.com/dms/image/C4D03AQGQM3HE777x_A/profile-displayphoto-shrink_200_200/0/1638140968124?e=1643846400&v=beta&t=JnGw4EhA-hSrE9t5zobBMgsd8hWD4easPZs_lTababA",
    ],
    jobTitle: "Translator and Writer",
    worksFor: {
      "@type": "Organization",
      name: "Spell Prijevodi",
    },
  }
  return (
    <Layout>
      <Seo title={intl.formatMessage({ id: "home" })} schemaMarkup={schema} />
      <BgImage image={pluginImage} className="home-bg-one"></BgImage>
      <div className="home-wrap-one">
        <section class="home-text">
          <div class="home-text-one">
            <FormattedMessage id="home-text.first" />
          </div>
          <div class="home-text-two">
            <StaticImage
              className="potpis"
              src="../images/Potpis.png"
              alt="Signature"
              placeholder="blurred"
              layout="constrained"
            />
          </div>
          <div class="home-text-three">
            <FormattedMessage id="home-text.second" />
          </div>
        </section>
        <section className="home-type">
          <Typewriter
            words={[
              intl.formatMessage({ id: "trans" }),
              intl.formatMessage({ id: "proof" }),
              intl.formatMessage({ id: "content" }),
              intl.formatMessage({ id: "writer" }),
            ]}
            loop={0}
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </section>
        <section className="home-arrow">
          <a
            tabIndex="0"
            href="#next"
            role="button"
            aria-label="Arrow"
            className="arrow"
          >
            down
          </a>
        </section>
      </div>
      <BgImage id="next" image={pluginImage1} className="home-bg-one"></BgImage>
      <div className="home-wrap-two">
        <div className="home-container-two-article">
          <article className="home-article">
            <h3>
              <FormattedMessage id="home-article.meet" />
            </h3>
            <h1>
              <FormattedMessage id="home-article.introduction" />
            </h1>
            <p>
              <FormattedMessage id="home-article.first" />
            </p>
            <p>
              <FormattedMessage id="home-article.second" />
            </p>
            <p>
              <FormattedMessage id="home-article.third" />
            </p>
            <p>
              <FormattedMessage id="home-article.fourth" />
            </p>
          </article>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
